.text-white {
  color: #fff;
}

.font-bold {
  font-weight: bold;
}

.text-2xl {
  font-size: 2rem;
}

.tracking-tight {
  letter-spacing: -0.05em;
}

.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.space-x-4 > * + * {
  margin-left: 1rem;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, #202020, #000000, #101010);
}

.text-green-200 {
  color: #38a169;
}

.logo-image {
  width: 100px;
  height: auto;
}

nav {
  background-color: #202020;
}

.text-white {
  color: #fff;
}

.text-white.hover\:hover:text-green-200 {
  color: #38a169;
}

.navlink-active {
  color: #38a169;
}

.hover-underline {
  position: relative;
  display: inline-block;
}

.hover-underline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out;
}

.hover-underline:hover::before {
  visibility: visible;
  transform: scaleX(1);
}
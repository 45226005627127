:root {
    --primary-color: #007BFF;
    --secondary-color: #6C757D;
    --background-color: #F8F9FA;
    --text-color: #212529;
  }

  .bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--primary-color), var(--secondary-color), #003f3c);
  }

  body {
    font-family: 'Roboto', sans-serif;
    color: var(--text-color);
  }
  .nav {
    background-color: var(--primary-color);
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav-logo {
    width: 100px;
    height: auto;
  }
  
  .nav-links {
    display: flex;
    gap: 2rem;
  }
  
  .nav-link {
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  .nav-link:hover {
    color: var(--secondary-color);
  }
  .hero {
    text-align: center;
    padding: 4rem 0;
  }
  
  .hero-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 2rem;
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .hero-intro {
    font-size: 1.5rem;
    color: var(--secondary-color);
  }
  
  .project-card {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1.5rem;
    transition: box-shadow 0.2s;
  }
  
  .project-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .project-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .project-description {
    color: var(--secondary-color);
  }
  
  .project-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .project-link {
    display: inline-block;
    margin-top: 1rem;
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .project-link:hover {
    background-color: var(--secondary-color);
  }
  .contact-button {
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 999px;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .contact-button:hover {
    background-color: var(--secondary-color);
  }

  .skill-button {
    background-color: #e2e8f0;
    color: #4a5568;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    transition: background-color 0.3s;
  }
  
  .skill-button:hover {
    background-color: #cbd5e0;
  }
  
 
.hover-text-url-underline {
  position: relative;
  display: inline-block;
}

.hover-text-url-underline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: var(--primary-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out;
}

.hover-text-url-underline:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.required-label {
  color: red;
}
